@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,600&display=swap");

body {
    font-family: "Noto Sans", sans-serif;
}
.w3-button {
    word-break: break-word;
    word-wrap: break-word;
    white-space: normal;
}
.flex-container {
    display: flex;
    .w3-col {
        float: none;
    }
}

.row-item {
    &.lb-name { 
        display: inline-block; 
        width: 100%;
        text-align: left;
        padding: 4px 8px;
    }
}
@media only screen and (max-width: 600px) { 
    .flex-container {
        flex-direction: column;
    }
}
$activeColor :#fcc512;
.info-box {
    height:100%;
    display: flex;
    flex-direction: column;
    h4 {
        // color:$activeColor
    }
    h6 {
        margin-bottom: 20px;
    }
    .w3-center {
        margin-top: auto;
    }
}
.app-top-alert {
    position: fixed;
    width: 100%;
    z-index: 10000;
    text-align: center;
    top: 0px;
    left: 0px;
    letter-spacing: 1px;
    font-weight: bold;
    padding: 8px 28px;

    h5 {
        font-size: 14px;
    }
}
.w3-content {
    max-width: 1200px;
}
.rh-missions {
    ul {
        padding: 0px;
        li {
            list-style-type: none;
            padding: 0px 0px 2px 0px;
        }
    }
}

.kf-header {
    background: #505051;
    color: white;
}

.kf-header-2 {
    background: white;
    padding: 0px 4px;

    .w3-button {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        margin: 0px 6px;
        padding: 8px 8px;
    }
}

// .w3-button:hover {
//     background-position: right center; /* change the direction of the change here */
// }

// .w3-button {
//     background: linear-gradient(to right, #fbc2eb 0%, #a6c1ee 51%, #fbc2eb 100%);
// }

button {
    outline: none;
}

.kf-show {
    visibility: visible;
}

.kf-hide {
    visibility: hidden;
}

#app {
    background: #ededed;
}

.kf-top-bar {
    border-bottom: solid 6px #ededed;
}

.kf-top-bar-filter {
}

.kf-top-bar-filter .kf-carot {
    width: 8px;
    height: 8px;
    border: 2px solid;
    display: inline-block;
    transform: rotate(45deg);
    border-left: 0px;
    border-top: 0px;
    position: relative;
    top: -3px;
}

// .header-top-bar{
//     .w3-bar-item {
//         //  margin:0px 2px;
//     }
// }

.google-maps-supply {
    position: relative;
    padding-bottom: 75%; // This is the aspect ratio
    height: 0;
    overflow: hidden;
}

.kf-request-item {
    .w3-button {
        margin: 4px;
    }
}

.google-maps-supply iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}

.kf-top-bar-filter .w3-dropdown-content {
    position: fixed;
}

.w3-modal {
    padding-top: 20px;
}
.kf-header-2 {
    padding: 4px 0px 0px 0px;
    a.w3-button {
        // padding:6px 8pxpx 8px;
    }
    a.w3-button.active,
    a.w3-button:hover {
        background: linear-gradient(180deg, #c3c1c1, #ededed);
        position: relative;
        color: black !important;
    }
}

.top-row a.w3-link-button.active {
    border-bottom: solid 2px white;
    background: transparent !important;
    color: white !important;
}

.top-row a.w3-link-button:hover {
    border-bottom: solid 2px rgb(0, 233, 250);
    background: transparent !important;
    color: white !important;
}

.header-logo {
    span {
        font-size: 22px;
        vertical-align: bottom;
        left: -5px;
        position: relative;
        top: 2px;
    }
}
.top-row a.w3-link-button {
    border-bottom: solid 2px transparent;
    padding: 8px 8px 6px 8px;
}

.cgs-loader {
    border: 6px solid #fafafa;
    /* Light grey */
    border-top: 6px solid #fcc512b0;
    /* Blue */
    border-radius: 50%;
    width: 90px;
    background-color: transparent;
    height: 90px;
    animation: spin 1s linear infinite;
    margin: auto;
    margin-top: 10px;
}
.spinner {
    img {
        width: 64px;
        position: absolute;
        left: 50%;
        top: 32px;
        margin-left: -32px;
    }
}

.rescue-table {
    tr td {
        vertical-align: top;
    }
}

.kf-request-item {
    word-wrap: break-word;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.kf-manage-container {
    min-height: 100vh;
    padding-bottom: 100px;
}
.footer-links {
    a{ 

        display: block;
        margin-bottom: 8px;
        text-decoration: none;
        letter-spacing: 1px;
        font-size: 14px;
        &:hover {
            text-decoration: underline;
            color: $activeColor;
        }
    }
}
//KF-LandingPage
.showcase {
    background-position: center;
    min-height: 650px;
    padding: 140px 100px;
    color: #ffffff;
    .w3-section {
        text-align: left;
    }
    p {

        font-size: 14px; 
    }
    h1 {
        font-size: 45px;
    }
    button {
        margin-top: 20px;
    }
}
.w3-tag { 
    position: relative;
    padding:2px 6px 2px 18px;
    margin: 0px 6px 4px 0px;

    &::before {
        position: absolute; 
        border: transparent solid 3px ;
        border-left:white solid 3px; 
        border-top:white solid 3px; 
        content:'';
        width:6px;
        height:6px;
        top:0px;
        left:0px
    }
    .dot {
        position: absolute; 
        border-radius: 50%; 
        background:white;
        content:'';
        width: 6px;
        height: 6px;
        top: 4px;
        left: 6px;
    }
    // &::after 
    // }
}

.navigation {
    background: rgba(255, 255, 255, 0.3);
}

 
.features h6 {
    margin-top: 65px;
}

.latest-news {
    background: #fdf9d5;
    margin-top: 10px;
    height: 400px;
    text-align: left;
}

 

.section3 img {
    width: 100%;
}

.section3 h6 {
    margin-top: 65px;
}

 
.section4 h6 {
    margin-top: 65px;
}

.section4-bgdimg {
    background-position: center;
    height: 200px;
    width: 100%;
}

.section5 h6 {
    margin-top: 65px;
}

.section5 img {
    padding: 50px;
}

//Create new Mission//
.mission-details input {
    border-radius: 5px;
    border-style: ridge;
}

 
.mission-details select {
    border-radius: 5px;
    border-style: ridge;
}

//Footer//

.section6 {
    // height: 100px;
    // width: 100%;
    background-color: black;
}

.section6 img {
    height: 80px;
    width: 150px;
}

.section6 a {
    color: #9a9c9a;
    font-size: 12px;
}

.section7 {
    background-color: #333333;
}

.section7 p { 
    color: #9a9c9a;
}

//Phone Number authentication

.phone-number input {
    margin: 4px;
    padding: 20px;
    padding-top: 11px;
    padding-bottom: 12px;
    border-radius: 5px;
}

.phone-number button {
    margin: 10px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: white;
    background-color: #148cf5;
    font-size: medium;
}

.resentOtp {
    text-decoration: underline;
    cursor: pointer;
}

.rh-certificate-message {
    font-size: 20px;
    b {
        font-size: 22px;
    }
}
//Auth Modal
.auth-modal {
    height: 90%;

    .label {
        font-size: 14px;
        color: #707070;
    }

    .label-2 {
        color: #393939;
    }

    .authcontent-dropdown {
        background-color: #161616;
        height: 40px;
        width: 60px;
        border-radius: 7px;
        font-size: small;
        color: #ededed;
    }

    .phone-input-entry {
        width: 100%;
        padding: 9px 20px;
        box-sizing: border-box;
        border: 2px solid #161616;
        border-radius: 6px;
        background: #f5f5f5;
        float: left;
    }

    .button-next {
        background: #fcc512;
        border-radius: 6px;
        width: 100%;
        height: 45px;
    }

    .fb-g-button {
        width: 100%;
        height: 40px;
        background-color: #fef3cd;
        color: black;
    }

    .authcontent-hr1 {
        float: left;
    }

    .authcontent-hr2 {
        float: left;
    }

    .password-input {
        width: 100%;
        padding: 12px 20px;
        border: 1px solid #b2b2b2;
        border-radius: 6px;
        background: #f5f5f5;
    }

    .login-button {
        background: #fcc512;
        border-radius: 6px;
        width: 100%;
        height: 45px;
        // margin-bottom: 15px;
        // margin-top: 177px;
        font-size: large;
    }

    .register-button {
        background: #fcc512;
        border-radius: 6px;
        width: 100%;
        height: 45px;
        font-size: large;
    }

    .verify-otp {
        text-align: center;
        height: 45px;
        border-radius: 6px;
        background-color: #f5f5f5;
        width: 55px;
        margin: 0 8px;
    }

    .full-height {
        height: 100%;
    }
}

.lister {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    background: white;
    border-radius: 8px;
    min-height: 150px;
    .lister-info {
        width: 100%;
        flex-grow: 1;
    }
    .lister-action {
        background-color: #cccccc;
        border-radius: 0px 8px 8px 0px;
        width: 300px;
        flex-grow: 0;
        .w3-button {
            max-width: 200px;
        }
    }
}

@media only screen and (max-width: 600px) {
    .lister {
        display: flex;
        flex-direction: column-reverse;
        margin-bottom: 16px;
        border-radius: 8px;
        .lister-info {
            flex: auto;
            // width:75%;
        }
        .lister-action {
            border-radius: 8px 8px 0px 0px;
            width: 100%;
        }
    }
}

.tickets {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.tickets-people-count {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}
.tickets-people-count-number {
    border: #161616;
    padding: 6px 12px;
    background: #121212;
    border-radius: 25px;
    color: #ec6d6d;
    font-weight: bold;
    text-align: center;
}
.tickets-type {
    font-size: 18px;
    font-weight: 100;
}
.tickets-status {
    border: #0647c6;
    color: #0647c6;
    border-radius: 36px;
    background-color: #e1ebfe;
    padding: 4px; 
}
.tickets-status-new {
    border: #df695a;
    color: #df695a;
    border-radius: 36px;
    background-color: #fbecea;
    padding: 4px; 
}
.tickets-status-todo {
    border: #3779fb;
    color: #3779fb;
    border-radius: 36px;
    background-color: #e1ebfe;
    padding: 4px; 
}
.tickets-status-doing {
    border: #48b545;
    color: #48b545;
    border-radius: 36px;
    background-color: #eaf5ea;
    padding: 4px; 
}
.tickets-status-completed {
    border: #44a0a6;
    color: #44a0a6;
    border-radius: 36px;
    background-color: #eaf5f5;
    padding: 4px; 
}
.tickets-content-left {
    display: inline-block;
    min-width: 80px;
    position: relative;
    font-size: 14px;
    color: #707070;
    font-weight: bold;
    padding: 0px 8px 0px 0px;
    &::after {
        position: absolute;
        right: 0px;
        top: 0px;
        content: ":";
    }
}
.tickets-content-right {
    font-size: 14px;
    color: #161616;
}
.tickets-content-bottom {
    display: flex;
    flex-direction: column;
}

.lang-option-container {
    right: 15px;
    min-width: 80px;
}

.rh-status-item {
    border: solid 1px white;
}

.time-range-block {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 4px;
    padding-bottom: 8px;

    .from-to {
        input {
            padding: 6px;
        }
        display: flex;
        flex-direction: column;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.total-issues {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f3f7ff;
    color: #0f3c95;
    height: 100%;
}
.total-demands {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #feecec;
    color: #ab0b0b;
    height: 100%;
}

//StatusList

.tickets-font {
    display: flex;
    align-items: center; 
}

.leader-board-table {
    td {
        vertical-align: middle;
    }
}

.leader-board-row {
    display: flex;
    flex-direction: row; 
    justify-content: space-between;
    padding: 4px;
    align-items: center;
    margin-bottom: 4px;
    background: #ededed;
    border-radius: 5px;

    .leader-board-total {
        padding: 4px;
        background: #fcc512;
        border-radius: 4px;
        width: 46px;
        color: black;
        text-align: center;
    }

}

.tickets-people-count-lb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    max-width: 61px;
}

.mobile-top-right {
    display: flex;
    align-items: center;
    justify-content: end;
}

.request-addmodal {
    .w3-modal-content {
        max-width: 600px;
    }
}

.background {
    background-color: #f0f9ff;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
